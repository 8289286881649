import {Footer} from 'components/footer';
import {StyledLink} from 'components/link';
import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls';
import {AuthContext} from 'utils/authProvider';
import {getEnvironment} from 'utils/environment';

export interface DashboardLayoutProps {
  children: React.ReactNode;
  setIsSigningOut?: (isSigningOut: boolean) => void;
  signOutButton?: boolean;
  homeLink?: boolean;
}

export function DashboardLayout({
  children,
  signOutButton,
  homeLink,
  setIsSigningOut = () => {},
}: DashboardLayoutProps) {
  const {signout} = useContext(AuthContext);

  return (
    <div className="flex flex-col min-h-screen p-0 md:p-6">
      <NonProdWarningBanner />
      <div className="mx-auto w-full max-w-6xl flex flex-col flex-grow pt-4 px-4 md:pt-10 md:px-10">
        <div className="flex-grow flex flex-col">
          <div className="flex justify-between items-center mb-8">
            <Link to={URL_ROUTES.HOME}>
              <img
                src="/images/logo_letterform_yw_trim.png"
                alt="Additive Logo"
                className="max-h-12"
              />
            </Link>
            {signOutButton && (
              <div
                className="ml-4 text-lg text-white hover:underline cursor-pointer"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsSigningOut(true);
                  signout();
                }}
              >
                Sign&nbsp;out
              </div>
            )}
          </div>
          {homeLink && (
            <div className="pb-6">
              <StyledLink strong theme="white" to={URL_ROUTES.HOME} text="‹ Home" />
            </div>
          )}
          <Content>{children}</Content>
        </div>
        <Footer />
      </div>
    </div>
  );
}

function Content({children}: {children: React.ReactNode}) {
  return <div className="max-w-6xl flex flex-col flex-grow">{children}</div>;
}

/**
 * A warning banner that is displayed for non-production environments.
 */
function NonProdWarningBanner() {
  const [dismissed, setDismissed] = useState(false);
  if (dismissed) {
    return;
  }

  const env = getEnvironment();
  if (env !== 'development' && env !== 'staging') {
    return;
  }

  return (
    <div className="bg-yellow text-black text-center py-2">
      <strong>Warning:</strong> This is a non-production environment.{' '}
      <strong>Do not upload unredacted data.</strong>
      <button
        className="ml-2 text-black underline"
        onClick={() => {
          setDismissed(true);
        }}
      >
        Dismiss
      </button>
    </div>
  );
}
