export function formatDate(date: Date | undefined) {
  if (!date) {
    return '';
  }
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function formatTimeString(date: Date | undefined) {
  if (!date) {
    return '';
  }
  return date.toISOString();
}
