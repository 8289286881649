import {createAsyncThunk} from '@reduxjs/toolkit';
import {additiveAPI} from 'reduxStore/services';
import {AppDispatch} from 'reduxStore/store';
import {URL_ROUTES} from 'routes/urls';

/**
 * Logout action to fetch WorkOS logout url
 * and log the user out of the application.
 */
export const logoutAction = createAsyncThunk('user/logout', async (arg, thunkApi) => {
  const dispatch = thunkApi.dispatch as AppDispatch;
  // Reset api state to invalidate all queries (cache) on logout.
  // Note: This does not invalidate local component hook state.
  await dispatch(additiveAPI.util.resetApiState());

  // Note: createAsyncThunk will always return a resolved promise to the caller. Error handling
  // is done by caller. See https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions.
  try {
    // Redirect to logout url to log the user out of the application.
    // This endpoint then redirects to WorkOS which signs the user out and
    // redirects back to the application.
    window.location.href = URL_ROUTES.API_LOGOUT;
  } catch (error) {
    throw new Error('Unable to logout');
  }
});
