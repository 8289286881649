import {update as intercomUpdate} from '@intercom/messenger-js-sdk';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const RouteChangeListener: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    handleRouteChange();
  }, [location]);

  const handleRouteChange = () => {
    if (process.env.NODE_ENV === 'test') {
      // Skip intercom in tests.
      return;
    }

    intercomUpdate({
      last_request_at: new Date().getTime(),
    });
  };

  return <></>;
};
