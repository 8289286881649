import {Intercom} from '@intercom/messenger-js-sdk';
import {Outlet} from 'react-router-dom';

// This layout is applied to all pages (authenticated or not).
export function RootLayout() {
  // Making this call instantiates the Intercom Messenger within the
  // application. See AuthProvider for user-specific Intercom configuration.
  Intercom({
    app_id: window['INTERCOM_APP_ID'],
  });

  return (
    <div className="min-h-screen bg-black">
      <Outlet />
    </div>
  );
}
