import {useEffect} from 'react';

/**
 * Custom hook that triggers a callback function whenever the browser window gains focus.
 *
 * @param {Function} callback - The function to call when the window gains focus.
 */
export const useWindowFocus = (callback) => {
  useEffect(() => {
    const onFocus = () => {
      callback();
    };

    window.addEventListener('focus', onFocus);

    // Cleanup by removing the event listener when the component unmounts or on re-render
    return () => window.removeEventListener('focus', onFocus);
  }, [callback]);
};
