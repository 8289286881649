import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {User} from 'types/models';

import {removeUser} from './localStorage';

export interface AuthContextType {
  user: User | undefined;
  signout: () => void;
  signin: (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => void;
  fetchUser: (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => void;
}

/**
 * Redirects the user to the login page if the error is an auth error.
 * @returns true if the error is an auth error, false otherwise
 */
export function enforceAuth(error: FetchBaseQueryError | SerializedError | undefined) {
  const hasAuthError =
    error && 'status' in error && (error?.status === 401 || error?.status === 403);
  if (hasAuthError) {
    removeUser();

    // hard refresh
    window.location.reload();
  }
  return hasAuthError;
}
