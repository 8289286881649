import classnames from 'classnames';
import IconStatusInProgress from 'icons/status_in_progress.svg';
import UploadIcon from 'icons/upload.svg';
import Cookies from 'js-cookie';
import {DashboardLayout} from 'layouts/dashboard';
import pluralize from 'pluralize';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getUser} from 'utils/localStorage';

// MUST MATCH BACKEND settings.py
const MAX_FILE_SIZE_MB = 500;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

type FileUploadResponse = 'waiting' | 'uploading' | 'error' | 'success';
interface FileStatus {
  status: FileUploadResponse;
  message: string;
}

export function Upload() {
  const navigate = useNavigate();

  const [fileStatus, setFileStatus] = useState<FileStatus>({
    status: 'waiting',
    message: '',
  });

  const [selectedFiles, setSelectedFiles] = useState<FileList | undefined>(undefined);
  const [mouseIsDragging, setMouseIsDragging] = useState(false);

  const user = getUser();
  const userIsDev = !!user?.dev;
  const [renderIWP, setRenderIWP] = useState<boolean>(false);

  const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    // Disable upload if already uploading
    if (fileStatus.status === 'uploading') {
      return;
    }

    // Stop if no files
    if (!event.target.files) {
      return;
    }
    setSelectedFiles(event.target.files);
    setMouseIsDragging(false);

    setFileStatus({
      status: 'uploading',
      message: `Uploading ${pluralize('file', event.target.files.length, true)}…`,
    });

    const files = event.target.files;
    const form = new FormData();

    if (!files) {
      setFileStatus({
        status: 'error',
        message: 'No file selected',
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > MAX_FILE_SIZE) {
        setFileStatus({
          status: 'error',
          message: `File is too large. (> ${MAX_FILE_SIZE_MB}MB)`,
        });
        return;
      }
      form.append(`file`, file);
    }

    const csrfmiddlewaretoken = Cookies.get('csrftoken') ?? '';
    form.set('csrfmiddlewaretoken', csrfmiddlewaretoken);
    try {
      form.set('user-timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    } catch (e) {
      form.set('user-timezone', 'US/Eastern');
    }
    form.set('render-iwp', renderIWP.toString());

    try {
      const res = await fetch('/api/upload_file', {
        method: 'POST',
        body: form,
        cache: 'no-cache',
        credentials: 'same-origin',
      });
      if (!res.ok) {
        const response = await res.json();
        setFileStatus({
          status: 'error',
          message: response?.error,
        });
        return;
      }
      const response = await res.json();
      if (response) {
        setFileStatus({
          status: 'success',
          message: 'File uploaded!',
        });
        navigate('/thanks');
        return;
      }

      setFileStatus({
        status: 'error',
        message: `Something went wrong. Please try again.`,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`api/upload_file call failed: ${error}`);
      setFileStatus({
        status: 'error',
        message: `Something went wrong. Please try again.`,
      });
    }
  };

  return (
    <DashboardLayout homeLink>
      <div className="text-white font-semibold text-3xl pb-6">Upload partnership tax documents</div>
      <div className="text-white font-semibold text-sm pb-6">
        Upload a zipped folder containing K-1, K-3 or State K-1 PDFs. We will process them and email
        you with links to completed Excel and PDF workpapers.
      </div>
      <form
        action="/api/upload_file"
        encType="multipart/form-data"
        className={'relative overflow-hidden '}
      >
        <div
          className={`${mouseIsDragging ? 'bg-[#303030]' : 'bg-gray-darkest'} hover:bg-[#2F2F2F] border border-dashed border-gray-dark rounded-md transition-colors duration-300`}
        >
          <div className="w-full h-full flex justify-center items-center">
            {fileStatus.status === 'uploading' && (
              <div className="text-white text-md text-center my-20 pt-5">
                <>
                  <div className="inline-block animate-spin">
                    <div style={{transform: 'scale(2)'}}>
                      <IconStatusInProgress />
                    </div>
                  </div>
                  {selectedFiles && (
                    <div className="text-center text-white my-4">
                      Uploading {pluralize('file', selectedFiles.length, true)}…
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
          <div
            className={classnames('relative py-10', {
              hidden: fileStatus.status === 'uploading',
            })}
          >
            <div className="overflow-hidden">
              <input
                type="file"
                name="file"
                required
                multiple
                accept=".zip"
                disabled={fileStatus.status === 'uploading'}
                className={classnames(
                  'border border-gray-300 rounded absolute m-0 p-0 w-full h-full outline-none opacity-0 top-0 left-0',
                  {
                    'cursor-pointer': fileStatus.status === 'waiting',
                  },
                )}
                onChange={changeHandler}
                onDragOver={(e) => {
                  e.preventDefault();
                  setMouseIsDragging(true);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setMouseIsDragging(false);
                }}
              />
              <div
                className={classnames('p-6', {
                  'opacity-0': fileStatus.status === 'uploading',
                })}
              >
                <div className="flex justify-center items-center pb-6">
                  <UploadIcon />
                </div>
                <div className="font-medium text-center text-white">
                  {mouseIsDragging ? (
                    <>Drop files here!</>
                  ) : (
                    <>
                      <span className="text-blue font-semibold">Click to browse</span> or drag and
                      drop your ZIP files
                    </>
                  )}
                </div>
                <div>
                  <div className="text-center text-gray-light mt-2">
                    {/* List all the files uploaded */}
                    <div className="text-gray-light">
                      {selectedFiles &&
                        Array.from(selectedFiles).map((file) => (
                          <div key={file.name}>{file.name}</div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {fileStatus.status === 'error' && (
              <div className="text-red py-6 px-10 text-center" role="alert">
                <div>Could not upload file.</div>
                <div>{fileStatus.message}</div>
              </div>
            )}
          </div>
        </div>
        {userIsDev && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray cursor-pointer">
              <input
                type="checkbox"
                className="mr-2"
                checked={renderIWP}
                onChange={(event) => setRenderIWP(event.target.checked)}
              />
              <span className="text-white">Render IWP</span>
            </label>
          </div>
        )}
      </form>
    </DashboardLayout>
  );
}
