import {
  boot as intercomBoot,
  shutdown as intercomShutdown,
  update as intercomUpdate,
} from '@intercom/messenger-js-sdk';
import {createContext, ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logoutAction} from 'reduxStore/logoutAction';
import {AppDispatch} from 'reduxStore/store';
import {userSlice} from 'reduxStore/userSlice';
import {URL_ROUTES} from 'routes/urls';
import {
  GetSSOAuthURL,
  GetSSOAuthURLResponse,
  PostUserCurrentRequest,
  PostUserCurrentResponse,
} from 'types/api';
import {User} from 'types/models';
import {AuthContextType} from 'utils/auth';
import {fetchApi} from 'utils/fetchApi';
import {getUser, removeUser, saveUser} from 'utils/localStorage';

export const AuthContext = createContext<AuthContextType>(undefined!);
export function AuthProvider({children}: {children: ReactNode}) {
  const navigate = useNavigate();
  const local_user = getUser();
  const [user, setUser] = useState<User | undefined>(local_user);
  const dispatch = useDispatch<AppDispatch>();

  // Keep intercom in sync with the user state.
  // Shutdown ensures that no data leaks between the two states.
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      // Skip intercom in tests.
      return;
    }

    if (user) {
      const userArgs = {
        app_id: window['INTERCOM_APP_ID'],
        user_id: user.id,
        name: user.name,
        email: user.email,
        createdAt: user.created_at,
        user_hash: user.intercom_user_hash,
      };

      if (user.org_id && user.org_name) {
        userArgs['company'] = {
          id: user.org_id,
          name: user.org_name,
        };
      }
      // NOTE: For scenarios where the user is not present, the intercom
      // messenger is already loaded, and then a user becomes present, this call
      // seems to be insufficient to get the messenger to fetch the messages for
      // the actual user and operate in that context. However, since we redirect
      // for auth, that is not a concern as the redirect causes the page to load
      // with the user setup. If that changes in the future, this code may need
      // to change.
      intercomUpdate(userArgs);
    } else {
      // NOTE: This does _not_ clear the local state of the messenger, which the
      // logout handlers are responsible for. This just restarts the messenger
      // with no parameters when relevant.
      intercomBoot({
        app_id: window['INTERCOM_APP_ID'],
      });
    }
  }, [user]);

  const fetchUser = (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => {
    fetchApi<PostUserCurrentRequest, PostUserCurrentResponse>({
      url: '/api/users/current/',
      method: 'GET',
      onSuccess: (user_response) => {
        setUser(user_response);
        saveUser(user_response);
        userSlice.actions.setUser(user_response);
        onSuccessCallback(user_response);
      },
      onError: (error) => {
        setUser(undefined);
        removeUser();
        userSlice.actions.clearUser(undefined);
        onErrorCallback(error);
      },
    });
  };

  const signin = (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => {
    fetchApi<GetSSOAuthURL, GetSSOAuthURLResponse>({
      url: '/api/get_sso_auth_url',
      method: 'GET',
      onSuccess: (json) => {
        onSuccessCallback(json);
        window.location.href = json['authorization_url'];
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    });
  };

  /**
   * Logout the user and clean up client side state.
   */
  const _logout = async () => {
    // When the user is not present (e.g. logout), shutdown intercom, which
    // totally clears local state so that there is no leaked information
    // outside the user's session.
    // Note: Do not run intercom in test mode.
    if (process.env.NODE_ENV !== 'test') {
      intercomShutdown();
    }
    // Unset the user in the context and in local storage.
    removeUser();
    setUser(undefined);
    await dispatch(logoutAction())
      .unwrap()
      .catch(() => {
        // If the logout fails, redirect to the login page.
        // This is a fallback in case the logout API call fails.
        navigate(URL_ROUTES.LOGIN);
      });
  };

  const signout = async () => {
    try {
      await _logout();
    } catch (error) {
      // If the logout fails, redirect to the login page.
      navigate(URL_ROUTES.LOGIN);
    }
  };

  const value = {user, signin, signout, fetchUser};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
