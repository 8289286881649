import {Link} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls';

export interface LoginLayoutProps {
  children: React.ReactNode;
  title: string;
}

export function LoginLayout(props: LoginLayoutProps) {
  return (
    <div className="flex h-screen">
      <div
        className="w-full p-10 rounded-md m-auto bg-black md:shadow-loginGlow"
        style={{maxWidth: '700px'}}
      >
        <Link to={URL_ROUTES.HOME}>
          <img
            src="/images/logo_letterform_yw_trim.png"
            alt="Additive Logo"
            className="mb-12 max-h-20 mx-auto"
          />
        </Link>
        <div className="text-center">
          <h2 className="text-white text-xl font-semibold pb-6">{props.title}</h2>
        </div>
        {props.children}
      </div>
    </div>
  );
}
