import classnames from 'classnames';
import FileExcel from 'icons/file_excel.svg';
import FileGeneric from 'icons/file_generic.svg';
import FilePDF from 'icons/file_pdf.svg';
import {Link, LinkProps} from 'react-router-dom';

// Always hover blue! - Check with Dwight if changing.
export const LINK_COLORS = 'transition-colors duration-200 hover:text-blue-medium';

export interface StyledLinkProps extends LinkProps {
  text: string;
  theme: 'white' | 'gray';
  strong?: boolean;
}
export function StyledLink(props: StyledLinkProps) {
  const theme = props.theme ?? 'blue';

  // Remove non-Link props to be passed to the Link component.
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const {theme: _, strong: __, ...rest} = props;
  return (
    <Link
      {...rest}
      className={classnames(
        `text-${theme} underline`,
        props.strong ? 'font-bold' : 'font-medium',
        LINK_COLORS,
        props.className,
      )}
    >
      {props.text}
    </Link>
  );
}

export interface StyledDownloadLinkProps {
  downloadURL: string;
  fileType: string;
  text: string;
}
export function StyledDownloadLink(props: StyledDownloadLinkProps) {
  // Dwight doesn't want the icons for now. Check with him if changing.
  const includeIcon = false;

  return (
    <div className="text-white">
      <a href={props.downloadURL} download>
        <div className={classnames('inline-flex items-center', LINK_COLORS)}>
          {includeIcon && <div className="pr-3">{IconFile(props.fileType)}</div>}
          <div className="underline">{props.text}</div>
        </div>
      </a>
    </div>
  );
}

function IconFile(type: string) {
  switch (type) {
    case 'pdf':
      return <FilePDF />;
    case 'xlsx':
      return <FileExcel />;
    case 'zip':
      return <FileGeneric />;
  }
}
