/* eslint-disable no-null/no-null */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from 'types/models';

type SliceState = User | null;

const initialState: SliceState = null;

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState as SliceState,
  reducers: {
    setUser: (state, action: PayloadAction<SliceState>) => action.payload,
    clearUser: (state, action: PayloadAction<undefined>) => initialState,
    reset: () => initialState,
  },
});
