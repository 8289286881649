import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls';
import {AuthContext} from 'utils/authProvider';

export function AuthRedirect() {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const {fetchUser} = useContext(AuthContext);

  fetchUser(
    (json) => {
      navigate(URL_ROUTES.HOME);
    },
    (error) => {
      navigate(URL_ROUTES.LOGIN);
    },
  );

  return false;
}
