import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {Job, User} from 'types/models';

export const additiveAPI = createApi({
  reducerPath: 'additiveAPI',
  baseQuery: fetchBaseQuery({baseUrl: '/api/'}),
  keepUnusedDataFor: 1, // keep all unused data for 1 second
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getJobById: builder.query<Job, string>({
      query: (id) => `jobs/${id}`,
    }),
    getJobs: builder.query<Job[], void>({
      query: () => `jobs`,
    }),
    getUser: builder.query<User, void>({
      query: () => `users/current`,
    }),
  }),
});

// Export hooks for usage in functional components
export const {useGetJobsQuery, useGetJobByIdQuery, useGetUserQuery} = additiveAPI;
