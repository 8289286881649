import {Navigate, useLocation} from 'react-router-dom';
import {getUser} from 'utils/localStorage';

import {URL_ROUTES} from './urls';

/**
 * Private routes redirect the user to the login page if they are not logged in.
 */
export function Private({children}: {children: React.JSX.Element}) {
  const location = useLocation();
  if (!getUser()) {
    return <Navigate to={URL_ROUTES.LOGIN} state={{from: location}} replace />;
  }
  return children;
}
