import Rocket from 'icons/rocket.svg';
import {DashboardLayout} from 'layouts/dashboard.tsx';
import React from 'react';
import {Link} from 'react-router-dom';

export function NotFound() {
  return (
    <DashboardLayout>
      <div className="text-center flex-col bg-black text-gray-lightest m-auto">
        <Rocket
          aria-hidden="true"
          // @ts-expect-error SVG imports are not typed yet
          className="w-12 h-12 mb-6 mx-auto"
        />
        <h1 className="text-3xl font-bold pb-3">Oops!</h1>
        <p className="pb-3">
          Seems like this page took a spacewalk. Let us guide you{' '}
          <Link to="/" className="underline hover:text-white transition-colors">
            home
          </Link>
          .
        </p>
      </div>
    </DashboardLayout>
  );
}
