import {User} from 'types/models';

export function saveUser(user: User) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getUser() {
  const user = localStorage.getItem('user');
  if (!user) return undefined;
  try {
    return JSON.parse(user) as User;
  } catch (e) {
    removeUser();
  }
  return undefined;
}

export function removeUser() {
  localStorage.removeItem('user');
}
