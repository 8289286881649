import {combineReducers, configureStore} from '@reduxjs/toolkit';

import {logoutAction} from './logoutAction';
import {additiveAPI} from './services';
import {userSlice} from './userSlice';

const appReducer = combineReducers({
  user: userSlice.reducer,
  [additiveAPI.reducerPath]: additiveAPI.reducer,
});

const rootReducer = (state, action) => {
  // Reset the entire redux store on logout
  if (action.type === logoutAction.fulfilled.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`.
  // See https://redux-toolkit.js.org/api/getDefaultMiddleware for details.
  // This by default includes `redux-thunk` middleware.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(additiveAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
