import {ErrorMessage} from 'components/errorMessage';
import {StyledDownloadLink} from 'components/link';
import {DashboardLayout} from 'layouts/dashboard';
import {useParams} from 'react-router-dom';
import {useGetJobByIdQuery} from 'reduxStore/services';
import {JobOutput, JobOutputType} from 'types/models';
import {enforceAuth} from 'utils/auth';
import {jobTypeToName} from 'utils/files';

export function Result() {
  const {jobId} = useParams();
  const {data: job, error: apiError, isLoading} = useGetJobByIdQuery(jobId ?? '');
  if (enforceAuth(apiError)) return;

  // Bucket job outputs by file type
  const jobOutputByType =
    job?.outputs?.reduce((acc: Map<string, JobOutput[]>, output: JobOutput) => {
      const fileType = output.path.split('.').pop() as JobOutputType;
      if (fileType) {
        if (!acc.has(fileType)) {
          acc.set(fileType, []);
        }
        acc.get(fileType)?.push(output);
      }
      return acc;
    }, new Map<JobOutputType, JobOutput[]>()) ?? new Map<JobOutputType, JobOutput[]>();

  // Put the desired file type at the beginning of the Map
  function prioritizeFileType(
    map: Map<string, JobOutput[]>,
    fileType: string,
  ): Map<string, JobOutput[]> {
    const entries = Array.from(map.entries());
    const index = entries.findIndex(([key, _]) => key === fileType);
    if (index !== -1) {
      const [entry] = entries.splice(index, 1);
      entries.unshift(entry);
    }

    return new Map(entries);
  }

  // Put xlsm files at the top. In the future we may want to associate outputs
  // with output groups where each group has a title and an order index
  const prioritizedJobOutputByType = prioritizeFileType(jobOutputByType, 'xlsm');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Redux Toolkit thinks there is no `status` property
  const apiErrorStatus = +apiError?.status;

  return (
    <DashboardLayout homeLink>
      {isLoading ? (
        <p className="text-white">Loading results…</p>
      ) : !job || apiErrorStatus === 404 ? (
        <ErrorMessage error={new Error('Workpaper not found.')} />
      ) : !prioritizedJobOutputByType?.size ? (
        <ErrorMessage error={new Error('Workpaper had no output.')} />
      ) : apiError ? (
        <ErrorMessage error={apiError} />
      ) : (
        <>
          <h1 className="text-3xl text-white font-bold pb-6">Workpaper Results</h1>
          <h2 className="text-lg text-white pb-6">
            Your job has been successfully completed. You can download the files below.
          </h2>
          <div>
            {Array.from(prioritizedJobOutputByType.entries()).map(
              ([fileType, outputs]: [string, JobOutput[]]) => {
                return (
                  <div key={fileType} className="pb-2">
                    <h2 className="text-xl text-white font-bold pb-2">{jobTypeToName(fileType)}</h2>
                    <ul className="results pb-6">
                      {outputs.map((jobOutput) => {
                        const downloadURL = `/api/download/${jobOutput.path}`;
                        return (
                          <li key={jobOutput.name}>
                            <StyledDownloadLink
                              downloadURL={downloadURL}
                              fileType={fileType}
                              text={jobOutput.name}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              },
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
}
