import ArrowOutward from 'icons/arrow_outward.svg';
import React from 'react';
import {Link} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls.tsx';

export function Footer(): React.ReactElement {
  return (
    <footer className="bg-black text-gray py-4 mt-auto">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <Link to={URL_ROUTES.HOME}>
            <img src="/images/symbol_monochrome.svg" alt="Additive Logo" className="max-h-12" />
          </Link>
        </div>
        <div className="h-px bg-gray my-6" />
        <div className="flex justify-between items-center text-sm">
          <Link to="https://docs.additive.ai/" target="_blank" className="hover:underline">
            <div className="flex justify-between">
              Help Center
              <ArrowOutward
                // @ts-expect-error SVG imports are not typed yet
                className="mt-0.5 w-4 h-4 hidden sm:inline-block"
                aria-hidden="true"
              />
            </div>
          </Link>
          <div className="flex space-x-2">
            <Link to="mailto:hello@additive.ai" target="_blank" className="hover:underline">
              Contact Us
            </Link>
            <span className="hidden sm:inline" aria-hidden="true">
              &middot;
            </span>
            <Link
              to="https://www.additive.ai/privacy-policy"
              target="_blank"
              className="hover:underline"
            >
              Privacy Policy
            </Link>
            <span className="hidden sm:inline" aria-hidden="true">
              &middot;
            </span>
            <Link
              to="https://www.additive.ai/terms-of-service"
              target="_blank"
              className="hover:underline"
            >
              Terms of Service
            </Link>
            <span className="hidden sm:inline" aria-hidden="true">
              &middot;
            </span>
            <Link to="https://www.additive.ai/security" target="_blank" className="hover:underline">
              Security
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
