export function jobTypeToName(type: string) {
  switch (type) {
    case 'pdf':
      return 'PDF Workpaper';
    case 'xlsx':
      return 'Excel Workpaper';
    // HACK: This is a bit of a hack since we are hijacking xlsm file type to map to RSM
    // TODO: In the future we should have an output type which we can group by instead of
    // grouping by file extension
    case 'xlsm':
      return 'RSM Compatible Workpaper';
    case 'zip':
      return 'IWP Zip';
    default:
      return type;
  }
}
