import {ErrorMessage} from 'components/errorMessage';
import {DashboardLayout} from 'layouts/dashboard';
import {useGetUserQuery} from 'reduxStore/services';
import {enforceAuth} from 'utils/auth';

export function Settings() {
  const {data: userSettings, error: apiError} = useGetUserQuery();
  if (enforceAuth(apiError)) return;

  return (
    <DashboardLayout homeLink>
      {apiError ? (
        <ErrorMessage error={apiError} />
      ) : !userSettings ? (
        <ErrorMessage error={new Error('User settings not found.')} />
      ) : (
        <>
          <h1 className="text-2xl text-white font-bold pb-2">Settings</h1>
          <div className="mb-6">
            <div className="text-white">
              <div>
                <span className="font-bold">Email:</span> {userSettings.email}
              </div>
              {userSettings.dev && (
                <div>
                  <span className="font-bold">Developer Mode: </span>Enabled
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}
