/* eslint-disable react-refresh/only-export-components */
import classnames from 'classnames';
import IconStatusCompleted from 'icons/status_completed.svg';
import IconStatusFailed from 'icons/status_failed.svg';
import IconStatusInProgress from 'icons/status_in_progress.svg';
import {Job} from 'types/models';

/**
 * Add "UNCOMPLETED" status for jobs that are pending for more than 24 hours
 */
export type PatchedJobStatus = Job['status'] | 'UNCOMPLETED';

export interface JobStatusProps {
  status: PatchedJobStatus;
}

/**
 * A job status component
 */
export function JobStatus(props: JobStatusProps) {
  // Spin the icon for in progress
  const icon = (
    <span className={classnames({'animate-spin': props.status === 'PENDING'})}>
      <JobStatusIcon status={props.status} />
    </span>
  );

  // Only horizontally center the icon in the container if we're not including text
  return (
    <div className={'flex items-center lg:justify-start justify-center'}>
      {icon}
      <span
        className={classnames('hidden lg:block ml-2 capitalize', {
          italic: props.status === 'PENDING',
        })}
      >
        {formatStatus(props.status)}
      </span>
    </div>
  );
}

export function JobStatusIcon(props: {status: PatchedJobStatus}) {
  const Icon = {
    PENDING: IconStatusInProgress,
    COMPLETED: IconStatusCompleted,
    FAILED: IconStatusFailed,
    UNCOMPLETED: IconStatusFailed,
  }[props.status];
  return <span title={formatStatus(props.status)}>{<Icon />}</span>;
}

export function formatStatus(status: PatchedJobStatus) {
  return {
    PENDING: 'In progress',
    COMPLETED: 'Complete',
    FAILED: 'Failed',
    UNCOMPLETED: 'Uncompleted',
  }[status];
}
