import {showNewMessage} from '@intercom/messenger-js-sdk';
import {DashboardLayout} from 'layouts/dashboard';

export function Thanks() {
  const supportEmail = 'support@additive.ai';
  const supportSubject = 'K-1 Package Submission Question';
  // TODO: In the future it would be nice to pre-fill information about which
  // package was uploaded, but we can infer that if needed from account info,
  // and this is a reasonable starting point.
  const supportMessage = 'I have a question about my K-1 package submission';
  const mailto = `mailto:${supportEmail}?subject=${encodeURIComponent(supportSubject)}&body=${encodeURIComponent(supportMessage)}`;
  const onSupport = () => {
    showNewMessage(supportMessage);
  };
  const onEmail = (event) => {
    window.open(mailto);
    event.preventDefault();
  };
  return (
    <DashboardLayout homeLink>
      <div className="text-white font-semibold text-3xl pb-8">Upload succeeded</div>
      <p className="text-white pb-2">
        Your documents are being processed. Most workpapers are ready within 30 minutes, but larger
        documents might take longer to process.
      </p>
      <p className="text-white pb-10">You will receive an email upon workpaper completion.</p>
      <p className="text-white pb-6">
        If you have any questions, please reach out through
        <button className="text-blue underline mx-1" onClick={onSupport}>
          chat
        </button>
        or{' '}
        <a className="text-blue underline" href={mailto} onClick={onEmail}>
          {supportEmail}
        </a>
        .
      </p>
    </DashboardLayout>
  );
}
