/* eslint-disable react/prop-types */
import classnames from 'classnames';
import * as React from 'react';
export interface TextInputProps {}

export interface ButtonInputProps {
  full?: boolean;
}

// This file contains low-level components that are used to build up the form components.

export function TextInput(
  props: TextInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) {
  return (
    <input
      placeholder=""
      type="text"
      autoComplete="off"
      autoCapitalize="off"
      autoCorrect="off"
      {...props}
      className={classnames(
        'bg-gray-darkest text-white py-3 px-3 rounded-lg focus:outline-none focus:ring ring-white',
        props.className,
      )}
    />
  );
}

export function EmailInput(
  props: TextInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) {
  return (
    <input
      type="email"
      name="email"
      autoComplete="off"
      autoCapitalize="off"
      autoCorrect="off"
      placeholder="Your email"
      {...props}
      className={classnames(
        'bg-gray-darkest text-white py-3 px-3 rounded-lg focus:outline-none focus:ring ring-white',
        props.className,
      )}
    />
  );
}

export function PasswordInput(
  props: TextInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) {
  return (
    <input
      required
      type="password"
      name="password"
      placeholder={props.placeholder ?? 'Your password'}
      {...props}
      className={classnames(
        'bg-gray-darkest text-white py-3 px-3 rounded-lg focus:outline-none focus:ring ring-white',
        props.className,
      )}
    />
  );
}

export function CheckboxInput(
  props: TextInputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) {
  return <input type="checkbox" {...props} className={classnames('rounded-sm', props.className)} />;
}

export function ButtonInput(
  props: TextInputProps &
    ButtonInputProps &
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) {
  return (
    <button
      className={classnames(
        {
          'w-full': props.full,
        },
        // Desktop and mobile styles
        'transition-colors bg-blue hover:bg-blue-dark px-3 font-medium text-white overflow-hidden focus:outline-none focus:ring ring-white',
        // If the button is full width, make a bit larger and more rounded. More rounded on mobile.
        props.full ? 'md:py-2 py-1' : 'py-2',
        props.full ? 'md:rounded-sm rounded-lg' : 'rounded-sm',
        props.full ? 'md:text-base text-2xl' : 'text-base',
        props.className,
      )}
      type="submit"
    >
      {props.children}
    </button>
  );
}
