export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface Headers {
  [key: string]: string;
}

export interface FetchAPIParams<Request, Response> {
  url: string;
  headers?: Headers;
  body?: Request;
  method: HttpMethod;
  onSuccess: (data: Response) => void;
  onError?: (error: Error) => void;
  retry_count?: number;
}

// Note: Only works for JSON responses. Use fetch directly for other responses.
export async function fetchApi<Request, Response>(options: FetchAPIParams<Request, Response>) {
  const {url, onSuccess, onError, retry_count = 0} = options;

  try {
    const response = await fetch(url, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data: Response = await response.json();
    onSuccess(data as Response);
  } catch (error) {
    if (retry_count < 1) {
      onError?.(error);
    } else {
      fetchApi({
        ...options,
        retry_count: retry_count - 1,
      });
    }
  }
}

interface RetryOptions {
  maxRetries: number;
  delay: number;
  backoffFactor: number;
}

/**
 * Fetch a URL with retries on failure. Return response instead of using callbacks.
 *
 * @param url URL to fetch
 * @param options fetch options
 * @param retryOptions retry options. See RetryOptions for more details.
 * @returns fetch Response or Error
 */
export async function fetchWithRetry(
  url: string,
  options?: RequestInit,
  retryOptions: RetryOptions = {maxRetries: 3, delay: 1000, backoffFactor: 2},
): Promise<Response> {
  const {maxRetries, delay, backoffFactor} = retryOptions;
  let lastError: Error | undefined = undefined;

  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response;
    } catch (error) {
      lastError = error as Error;

      if (attempt < maxRetries - 1) {
        const waitTime = delay * Math.pow(backoffFactor, attempt);
        await new Promise((resolve) => setTimeout(resolve, waitTime));
      }
    }
  }

  throw lastError ?? new Error('Max retries reached');
}
