import * as Sentry from '@sentry/react';
import {LoginLayout} from 'layouts/login';
import {useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AppDispatch} from 'reduxStore/store';
import {URL_ROUTES} from 'routes/urls';
import {AuthContext} from 'utils/authProvider';

export interface LoginParams {
  error?: string;
}

export function Login(params: LoginParams) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {user, signin} = useContext(AuthContext);

  useEffect(() => {
    // If user is already logged in, redirect to home page
    if (user) {
      navigate(URL_ROUTES.HOME);
    }
  }, [dispatch, navigate, user]);

  const onInitiateSignIn = () => {
    signin(
      () => {
        // User successfully signed in
      },
      (error) => {
        Sentry.captureException(error);
      },
    );
  };

  return (
    <LoginLayout title="Sign in to your account">
      <div className="mt-10">
        <div className="flex justify-center my-8 text-md">
          <button
            type="button"
            className="rounded-md bg-blue hover:bg-blue-dark px-3.5 py-2.5 text-sm font-semibold w-48 text-white shadow-sm"
            onClick={onInitiateSignIn}
          >
            Sign in
          </button>
        </div>
      </div>
    </LoginLayout>
  );
}
