export type Environment = 'localhost' | 'development' | 'staging' | 'production' | 'unknown';

export function getEnvironment(hostname: string = window.location.hostname): Environment {
  const environmentMap: Record<string, Environment> = {
    localhost: 'localhost',
    'app-dev.additive.ai': 'development',
    'app-stage.additive.ai': 'staging',
    'app.additive.ai': 'production',
  };

  return environmentMap[hostname] || 'unknown';
}
