import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

export interface ErrorMessageProps {
  error: FetchBaseQueryError | SerializedError | undefined;
}

// See https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
export function ErrorMessage({error}: ErrorMessageProps) {
  if (!error) return;
  let message = '';
  if ('status' in error) {
    const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
    message = `Request failed with status code ${error.status}: ${errMsg}`;
  } else {
    message = error?.toString() ?? 'Unknown error';
  }
  return <div className="bg-red-500 text-white pv-2 rounded-lg my-4">{message}</div>;
}
